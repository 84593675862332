import React from 'react';

export default function Kopen() {
	return (
    <ul>
      <li>
        Tussen 10.30 en 12.00 uur is de ruimte met kleding voor iedereen vrij toegankelijk.
        Aan de vroege kant? Bezoek dan vanaf 10.00 uur alvast de aparte ruimte met speelgoed.
        Om 12.00 uur sluit de beurs.
      </li>
      <li>Wij verkopen zowel 2e handskinderkleding als 2e handsspeelgoed.</li>
      <li>De kleding en het speelgoed worden in 2 verschillende ruimtes verkocht.</li>
      <li>Hierdoor is het een overzichtelijke beurs met veel ruimte om te lopen en te snuffelen.</li>
      <li>Ook hebben beide ruimtes een eigen kassa en hoef je niet al te lang in de rij te staan.</li>
      <li>Let op! Er kan alleen contant betaald worden.</li>
      <li>Er is gratis parkeergelegenheid in de buurt.</li>
      <li>Let er wel op dat je parkeert waar het mag, er wordt gecontroleerd.</li>
    </ul>
);
}
