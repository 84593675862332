import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import 'primeflex/primeflex.css';

function InschrijvingNietGeopend() {
        console.log("Render inschrijving niet geopend");
        return <p>De inschrijving is nog niet geopend. Hou het in de gaten. Elk moment kan de inschrijving geopend worden.</p>;
}

function InschrijvingGesloten() {
        console.log("Render inschrijving gesloten");
        return <p>De inschrijving is gesloten. Als je op de reservelijst geplaats wil worden stuur dan een email naar <a href="mailto:altenieuw@gmail.com">altenieuw@gmail.com</a></p>;
}

function AantalInschrijvingenBereikt() {
        console.log("Render aantal inschrijvingen bereikt");
        return <div>Maximum aantal inschrijvingen bereikt</div>;
}

function NieuweDeelnemer(props) {
        return (
            
    <React.Fragment>
       Beste { props.deelnemer.naam },
       <br/>
       Je gegevens zijn geregistreerd bij <i>Altenieuw</i>.
       Het aan jou toegekende nummer is { props.deelnemer.nummer }
      
       <br/>
       
       Je ontvangt nog een bevestigings email met daarin ook aangegeven jouw registratie nummer.
      
       <br/>
       <br/>

       <Button label="Terug naar Registreren" onClick={props.terug}/>
        
    </React.Fragment>
        );
}

function Registreren (props) {
    
     const [ inschrijvingenGeopend, setInschrijvingenGeopend ] = useState(false);
     const [ inschrijvingenGesloten, setInschrijvingenGesloten ] = useState(false);
     const [ maximumAantalDeelnemers, setMaximumAantalDeelnemers ] = useState(0);
     const [ aantalActieveDeelnemers, setAantalActieveDeelnemers ] = useState(9999);
     const [ geregistreerde, setGeregistreerde ] = useState({});
     const [ opgeslagen, setOpgeslagen ] = useState(null);

    useEffect(() => {
        axios.get("/api/settings/inschrijvinggesloten")
            .then (res => {
                setInschrijvingenGesloten(res.data);
            })
        axios.get("/api/settings/inschrijvinggeopend")
            .then (res => {
                setInschrijvingenGeopend(res.data);
            })
        axios.get("/api/settings/maximumaantaldeelnemers")
            .then (res => {
                setMaximumAantalDeelnemers(res.data);
            })
        axios.get("/api/geregistreerde/aantalactievedeelnemers")
            .then (res => {
                setAantalActieveDeelnemers(res.data);
            })
    },[]);
    
    const inschrijvingGeopend = () => {
        console.log("inschrijvingGeopend: " + inschrijvingenGeopend);
        return inschrijvingenGeopend;
    }
    const inschrijvingGesloten = () => {
        console.log("inschrijvingGesloten: " + inschrijvingenGesloten);
        return inschrijvingenGesloten;
    }
    const aantalInschrijvingenBereikt= () => {
        console.log("aantalInschrijvingenBereikt: aantalActieveDeelnemers: " + aantalActieveDeelnemers);
        console.log("aantalInschrijvingenBereikt: maximumAantalDeelnemers: " + maximumAantalDeelnemers);
        console.log("aantalInschrijvingenBereikt: " + (aantalActieveDeelnemers >= maximumAantalDeelnemers));
        return aantalActieveDeelnemers >= maximumAantalDeelnemers;
    }
    const opslaan = (event) => {
        event.preventDefault();
        console.log("Opslaan: " + geregistreerde.naam);
        console.log("Opslaan: " + geregistreerde.email);
        console.log("Opslaan: " + geregistreerde.telefoon);
        axios.post("/api/geregistreerde/save", geregistreerde)
            .then(res => {
                console.log("Save then "  + res.data);
                setOpgeslagen(res.data);
            })
        return true;
    }
    
    const terugNaarRegistreren = () => {
        console.log("TerugNaarRegistreren");
        setOpgeslagen(null);
        setGeregistreerde({});
    }
    
    if (opgeslagen !== null && opgeslagen !== undefined) {
        return <NieuweDeelnemer deelnemer={opgeslagen} terug={() => terugNaarRegistreren()}/>;
    }
    if (!props.loggedIn && inschrijvingGesloten()) {
        return <InschrijvingGesloten/>;
    }
    if (!props.loggedIn && !inschrijvingGeopend()) {
        return <InschrijvingNietGeopend/>;
    }
    if (!props.loggedIn && aantalInschrijvingenBereikt()) {
        return <AantalInschrijvingenBereikt/>;
    }
    console.log("Render registreren");
    return (
        <React.Fragment>
            <div className="pb-2">
                Je kunt je op deze pagina registreren.
            </div>
            <div className="pb-2">
                Vul hieronder jouw gegevens in en druk op <b>'Opslaan'</b>.
            </div>
            <div className="pb-2">
                Je gegevens worden opgeslagen en er wordt een registratie nummer aan jou toegekend.
                Je ontvangt nog een bevestigingse-mail met daarin jouw registratie nummer.
            </div>
            <div className="pb-2 text-red-500">
                Heb je al een nummer van vorig jaar en wil je deze behouden stuur ons dan een e-mail met daarin nummer, naam en opgegeven e-mail adres.
            </div>

      <form onSubmit={opslaan}>
          <div className="grid field" style={{paddingTop:'0.2em'}}>
              <label htmlFor="naam"  style={{width: 150}} className="col-fixed">Naam:</label>
              <div className="col">
                <InputText 
                    id="naam"
                    size="40"
                    required={true}
                    value={geregistreerde.naam}
                    onChange={(e) => setGeregistreerde({
                        naam: e.target.value,
                        email: geregistreerde.email,
                        telefoon: geregistreerde.telefoon,
                    })} />
              </div>
          </div>
          <div className="grid field" style={{paddingTop:'0.2em'}}>
              <label htmlFor="email"  style={{width: 150}} className="col-fixed">Email:</label>
              <div className="col">
                <InputText 
                    id="email"
                    size="40"
                    type="email"
                    required={true}
                    value={geregistreerde.email}
                    onChange={(e) => setGeregistreerde( {
                        naam: geregistreerde.naam,
                        email: e.target.value,
                        telefoon: geregistreerde.telefoon
                    })} />
              </div>
          </div>
          <div className="grid field" style={{paddingTop:'0.2em'}}>
              <label htmlFor="naam"  style={{width: 150}} className="col-fixed">Telefoon:</label>
              <div className="col">
                <InputText 
                    id="telefoon"
                    size="40"
                    value={geregistreerde.telefoon}
                    onChange={(e) => setGeregistreerde( {
                        naam: geregistreerde.naam,
                        email: geregistreerde.email,
                        telefoon: e.target.value
                    })} />
              </div>
          </div>
            <div className="p-col-12" style={{paddingTop:'2em'}}>
          <Button label="Opslaan"/>
          </div>
      </form>
</React.Fragment>
)
}

export default Registreren;
