import React from 'react';

export default function Header () {
     const alteHeader = {
        height: "150px",
          backgroundImage: "url('./Altenieuw.WebsiteTitel.Blauw.png')",
          backgroundSize: "contain",
          backgroundRepeat: "repeat-x"
     }
     return <div style={alteHeader}></div>
}