import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import axios from 'axios'; 
import 'primeflex/primeflex.css';
import { useNavigate } from 'react-router-dom';

function Login(props) {
   const [ username, setUsername ] = useState("");
   const [ password, setPassword ] = useState("");
   const [ message, setMessage ] = useState("");
   let history = useNavigate();
    const Login = (event) => {
       const myusername = username;
       const mypassword = password;
       console.log("Username: " + myusername); 
       console.log("Password: " + mypassword); 
       event.preventDefault();
       axios.post("/api/login", { username: myusername, password: mypassword})
           .then(res => {
                const loggedin = res.data === true;
                if (loggedin) {
                    props.login();
                    history("/home");
                } else {
                    setMessage("Er is iets foutgegaan, probeer het opnieuw");
                }
                console.log(loggedin);
            });
    }
        return (
    <React.Fragment>
        { message !== "" &&
        <Message severity="error">{message}</Message>
        }
        <form onSubmit={Login}>
            <div className="p-col-12" style={{paddingTop:'2em'}}>
            <InputText 
                variant="outlined"
                label="Gebruikersnaam"
                value={username}
                onChange={(e) => setUsername(e.target.value)}/>
                </div>
            <div className="p-col-12" style={{paddingTop:'2em'}}>
            <InputText 
                variant="outlined"
                label="Wachtwoord"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}/>
                </div>
            <div className="p-col-12" style={{paddingTop:'2em'}}>
            <Button label="Inloggen"/>
                </div>
        </form>
    </React.Fragment>
);
}

export default Login;
