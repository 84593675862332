import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import axios from 'axios';
import 'primeflex/primeflex.css';

function Instellingen() {
    const [ inschrijvingGeopend, setInschrijvingGeopend ] = useState(false);
    const [ inschrijvingGesloten, setInschrijvingGesloten ] = useState(false);
    const [ maximumAantalDeelnemers, setMaximumAantalDeelnemers ] = useState(0);
    
    const toggleInschrijvingGesloten = () => {
        
        const n = !inschrijvingGesloten;
        setInschrijvingGesloten(n);
        console.log(`Inschrijving gesloten: ${n}`);
        axios.put("/api/settings/inschrijvinggesloten", {maximumAantalDeelnemers: maximumAantalDeelnemers, inschrijvingGeopend: n, inschrijvingGesloten: n })
            .then((res) => {
            })
    }

    const toggleInschrijvingGeopend = () => {
        
        const n = !inschrijvingGeopend;
        setInschrijvingGeopend(n);
        console.log(`Inschrijving geopend: ${n}`);
        axios.put("/api/settings/inschrijvinggeopend", {maximumAantalDeelnemers: maximumAantalDeelnemers, inschrijvingGeopend: n, inschrijvingGesloten: n })
            .then((res) => {
            })
    }

    const changeMaximumAantalDeelnemers = (e) => {
        const maximum = e.target.value;
        setMaximumAantalDeelnemers(maximum);
        console.log(`Maximum aantal: ${maximum}`);
        axios.put("/api/settings/maximumaantaldeelnemers", {maximumAantalDeelnemers: maximum, inschrijvingGeopend: inschrijvingGeopend })
            .then((res) => {
            })
    }

    useEffect(() => {
        axios.get("/api/settings/inschrijvinggesloten")
            .then((res) => {
                setInschrijvingGesloten(res.data);
            });
        axios.get("/api/settings/inschrijvinggeopend")
            .then((res) => {
                setInschrijvingGeopend(res.data);
            });
        axios.get("/api/settings/maximumaantaldeelnemers")
            .then((res) => {
                setMaximumAantalDeelnemers(res.data);
            });
    }, []);
    
    return (
            <React.Fragment>
                <h3>Instellingen</h3>
                     
                <div className="p-field-checkbox p-col-12">
                    <Checkbox inputId="geopend" onChange={toggleInschrijvingGeopend} checked={inschrijvingGeopend}/>
                    <label htmlFor="geopend"> Inschrijving geopend</label>
                </div>
                <div className="p-field-checkbox p-col-12">
                    <Checkbox inputId="gesloten" onChange={toggleInschrijvingGesloten} checked={inschrijvingGesloten}/>
                    <label htmlFor="gesloten"> Inschrijving gesloten</label>
                </div>
                <div className="p-field p-col-12 p-md-3" style={{paddingTop:'2em'}}>
                    <label htmlFor="aantalDeelnemers" style={{display: 'block'}}>Maximum aantal deelnemers</label>
                    <InputNumber
                        id="aantalDeelnemers"
                        inputId="aantalDeelnemers"
                        value={maximumAantalDeelnemers}
                        onValueChange={changeMaximumAantalDeelnemers}
                        mode="decimal"
                        showButtons
                        buttonLayout="horizontal"
                        style={{width: '4rem'}}
                        decrementButtonClassName="p-button-secondary"
                        incrementButtonClassName="p-button-secondary"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus" />
                </div>
            </React.Fragment>
        )
}

export default Instellingen;
