import React from 'react';

function Nieuws(props) {
    
        return <React.Fragment>
                <h2>Nieuws</h2>
                <div dangerouslySetInnerHTML={{__html: props.nieuws}} />
            </React.Fragment>
}

export default Nieuws;
