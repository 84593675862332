import React from 'react';

function Vrijwilligers() {

	return (
  <React.Fragment>
    <div className="pt-3">
      Wil je meehelpen als vrijwilliger, leuk en graag.
      De beurs draait op vrijwilligers en samen kunnen we dit duurzame project voortzetten.
      Zonder vrijwilligers is er geen beurs.
    </div>

    <div className="pt-3">
      Als vrijwilliger heb je een groot voordeel.
      Je mag als eerste snuffelen in de te verkopen kleding, je kunt dit rustiger doen, je betaalt geen administratiekosten bij
      kledinginbreng en daarnaast is het ook altijd erg gezellig. 
    </div>

    <div className="pt-3">
      Er zijn vrijwilligers nodig op de vrijdagavond bij het sorteren en klaarleggen,
      op de zaterdagochtend voordat de beurs start,
      tijdens de beurs en na de beurs om alle retour kleren/speelgoed weer bij zijn eigenaar te krijgen.
    </div>

    <div className="pt-3">
      Wanneer je de vrijdagavond of zaterdagochtend komt helpen vragen we of je ook tijdens de beurs of juist na de beurs komt helpen,
      dit gaat uiteraard in overleg.
    </div>

    <div className="pt-3">
      Bij het uitpakken van de kleren mag je beginnen met de maten die jijzelf voor je kinderen goed kunt gebruiken,
      vervolgens help je ook mee met andere maten.
      Alles gaat onder een gemoedelijke sfeer waarin we iedereen gelijke kansen geven.
    </div>

  </React.Fragment>
);
}

export default Vrijwilligers;
