import React from 'react';
import { Button } from 'primereact/button';

function Labelen() {

    const download = () => {
        window.location = "data/altenieuw-labels.doc";
    }
    
	return (
    <React.Fragment>
    <ul>
      <li>
        Onder het kopje labels vind je labels die je kunt downloaden en uitprinten.
      </li>
      <li>
        Bevestig aan ieder kledingstuk een Altenieuw label of eigen label met je verkoopnummer, de maat en de prijs die je ervoor wilt. Maak deze goed vast met een touwtje of veiligheidsspeld (géén knopspelden!).
      </li>
      <li>
        Als je eigen labels gebruikt, let er dan op dat je de labels van de retourspullen duidelijk met een rode stip markeert en voorziet van het woord 'retour'.
      </li>
      <li>
        Speelgoed, boeken, etc. mag je ook voorzien van een plaketiket, dat gemakkelijk te verwijderen is bij de kassa.
      </li>
      <li>
        <span className="font-bold text-red-500">Als je spullen retour wilt wanneer ze niet verkocht zijn, markeer de labels dan met een rode stip en vermeld het woord 'retour'</span>
      </li>
      
    </ul> 

    <div className="text-bold text-lg pb-4 pt-2">Labels</div>

    <Button onClick={download} label="Download labels"/>

  </React.Fragment>
);
}

export default Labelen;
