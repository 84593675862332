import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout(props) {
    let history = useNavigate();
    const logout = () => {
        console.log("Logout");
        props.logout();
        history("/home");
    }
    return (
        <button onClick={logout} className="p-menuitem-link">Uitloggen</button>
    )
}

export default Logout;
