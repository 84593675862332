import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Contact() {
    
    const [ contactpersonen, setContactpersonen ] = useState([]);

    useEffect(() => {
        axios.get("/api/contactpersonen/all")
            .then(res => {
                setContactpersonen(res.data);
            })
    });

    return (
      <React.Fragment>
        <p>Bel of mail naar:</p>
        { contactpersonen.map(contactPersoon => {
            let mailto = "mailto:" + contactPersoon.email;
            return (
                <div key={contactPersoon.id}>
                    email: <a href={mailto}>{contactPersoon.email}</a>
                    <br/>
                    telefoon: {contactPersoon.naam}, {contactPersoon.telefoon}
                </div>
            )}
        )}
        <p>Wie weet tot ziens bij <span className="font-italic">Altenieuw</span>!</p>
      </React.Fragment>
    )
}
    
export default Contact;
    
