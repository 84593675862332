import React from 'react';

function Privacy() {
	return (
  <React.Fragment>

<h2>
Algemene privacy verklaring
</h2>

 
<h3>
Hoe komen wij aan je gegevens?
</h3>

<p>Aanmelden gaat via onze website (www.altenieuw.nl). Om mee te kunnen doen dient u uw naam en e-mailadres (en eventueel telefoonnummer) op te geven.</p>


<h3>
Wat doen we met je gegevens?
</h3>

<p>Via de email hebben we contact over je verkoopnummer, informatie over de beurs, etc. Het telefoonnummer is handig om je te kunnen bellen als je niet op tijd bent om de spullen en opbrengst na de beurs op te halen.</p>


<h3>
Hoe lang bewaren we je gegevens?
</h3>

<p>We bewaren de gegevens voor zo lang als dat nodig is, om het doel waarvoor we de persoonsgegevens verkregen hebben te vervullen (tot na de beurs).</p>

<p>Het e-mailadres zal 1 jaar bewaard blijven om een herinnering te kunnen sturen als er een nieuwe beurs aan komt.</p>

<p>Je hebt altijd het recht om een verzoek in te dienen om uw gegevens direct na de beurs te laten verwijderen.</p>


<h3>
Met wie delen we de gegevens?
</h3>

<p>De gegevens worden alleen door Altenieuw voor de beurs gebruikt en niet met derden gedeeld.</p>


<h3>
Contact, mailing en reclame
</h3>

<p>Je ontvangt voor een nieuwe beurs een herinnering op het opgegeven e-mailadres. Je kunt altijd vragen of wij het e-mail willen verwijderen.</p>


<h3>
Inzicht in je gegevens?
</h3>

<p>Je kunt altijd kosteloos inzicht krijgen in de gegevens die wij van je hebben. Je kun ons ook vragen de gegevens aan te passen als het e-mailadres onjuist, onvolledig of irrelevant is geworden. Op www.altenieuw.nl vind je onze contactgegevens.</p>

<p>Voor vragen en opmerkingen over deze Privacy verklaring kunt u altijd contact opnemen.</p>


  </React.Fragment>
      
);
}

export default Privacy;    
