import React from 'react';


function Verkopen(props) {
	
		return (
  <React.Fragment>
    <div className="font-bold text-lg">Inleveren</div>
    
    <ul>
      <li>
        Vrijdag {props.datumVrijdag} tussen 16.30 en 17.30 uur.
      </li>
      <li>
        Kleding (maat 74 t/m maat 176).
      </li>
      <li>
        Kleding aanleveren per maat in een doos/zak, waarop deze maat duidelijk vermeld staat aan de buitenkant
      </li>
      <li>
        <i>Maatgroepen babykleding: maat 74-80, 86-92.</i>
      </li>
      <li>
        <i>Maatgroepen kinderkleding: maat 98-104, 110-116, 122-128, 134-140, 146-152, 158-164, 170-176.</i>
      </li>
      <li>
        Schoenen zijn ook welkom, net als laarzen, gympen, skates, schaatsen, etc.
      </li>
      <li>
        Ook speelgoed, buggies, fietsjes, stepjes, fietsstoeltjes, rugdragers, kinderboeken, cd’s, verkleedspullen, enz. zijn welkom. Los klein speelgoed graag in een zakje/doosje. Knuffels worden niet verkocht.
      </li>
      <li>
        <div className="text-red-700 underline">Grotere artikelen zoals meubels alleen aanbieden in overleg (i.v.m. ruimte).</div>
      </li> 
    </ul>
    
    <br/>
    
    <div className="font-bold text-lg">Ophalen en opbrengsten</div>
    
    <p>
      Zaterdag { props.datumZaterdag } tussen 16:30 en 17:00 uur staat een envelop met jouw opbrengsten voor je klaar, en de spullen die niet verkocht zijn en die je terug wilt hebben.
    </p>
    <p>
      Voor de door ons geleverde service en gemaakte kosten houden wij 20% van jouw opbrengsten in.
    </p>
    <p>
      Iedere inbrenger betaalt verder €1,- administratiekosten. <span className="font-bold">Wil je de niet verkochte spullen retour, dan betaal je  €2,- extra (dus €3,- in totaal) vanwege het extra werk dat dit na afloop oplevert.</span> Deze kosten worden achteraf verrekend met de opbrengst.
    </p>

    <p>
      Voor komende beurs geldt, dat we geen artikelen met een prijs lager dan €1,- retourneren.
      Deze artikelen gaan, wanneer ze niet verkocht zijn, naar de Dorcas of de speelgoedbank.
    </p>
    
    <br/>
    
    <div className="font-bold text-lg">Achtergebleven spullen</div>
    
    <p>
      Achtergebleven kleding gaat naar de Dorcas.
      Het overgebleven speelgoed schenken wij aan de Speelstoet.
      Bij alle goede doelen zijn de spullen meer dan welkom! 
    </p>
    <br/>
    
    <div className="font-bold text-lg">Overige</div>
    
    <ul>
      <li>
        Wij zijn niet aansprakelijk voor schade en/of het zoekraken van ingeleverde artikelen. Zorg dat je de labels goed bevestigt aan je spullen!
      </li>
      <li>
        Niet opgehaalde artikelen gaan naar het goede doel.
      </li>
    </ul>
    
  </React.Fragment>
);
}

export default Verkopen;
