import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

function Geregistreerden() {
    
    const [ geregistreerden, setGeregistreerden ] = useState([]);
    const [ afleveringen, setAfleveringen ] = useState();
    const toast = useRef(null);
    
    useEffect(() => {
        axios.get("/api/geregistreerde/all")
        .then(res => {
            setGeregistreerden(res.data.sort((a,b) => a.nummer - b.nummer));
            });
    }, []);
    
    
    const save = (g) => {
        console.log("Save " + g.naam +" " + g.actief);
        axios.put("/api/geregistreerde/save", g)
            .then(res => {
                console.log("Save then "  + res.data);
            })
    }

    const toggleActief = (ev, g) => {
        ev.stopPropagation();
        const geregistreerdenKopie = geregistreerden.slice();
        const ene = geregistreerdenKopie.find(e => e.id === g.id);
        ene.actief = !ene.actief;
        ene.afleveringen[0].actief = ene.actief;
        save(ene);
        setGeregistreerden(geregistreerdenKopie);
    }
    
    const toggleVrijwilligerVrijdag = (ev, g) => {
        ev.stopPropagation();
        const geregistreerdenKopie = geregistreerden.slice();
        const ene = geregistreerdenKopie.find(e => e.id === g.id);
        ene.vrijwilligerVrijdag = !ene.vrijwilligerVrijdag;
        setGeregistreerden(geregistreerdenKopie);
        save(ene);
    }
    
    const toggleVrijwilligerZaterdag = (ev, g) => {
        ev.stopPropagation();
        const geregistreerdenKopie = geregistreerden.slice();
        const ene = geregistreerdenKopie.find(e => e.id === g.id);
        ene.vrijwilligerZaterdag = !ene.vrijwilligerZaterdag;
        setGeregistreerden(geregistreerdenKopie);
        save(ene);
    }
    
    const toggleAflevering = (ev, g, a) => {
        ev.stopPropagation();
        const geregistreerdenKopie = geregistreerden.slice();
        const ene = geregistreerden.find(e => e.id === g.id);
        const aflevering = ene.afleveringen.find(af => af.id === a.id);
        aflevering.actief = ! aflevering.actief;
        setGeregistreerden(geregistreerdenKopie);
        save(ene);
    }
    
    const mail = (event, geregistreerde) => {
        event.stopPropagation();
        console.log("Mail: " + geregistreerde.naam);
        axios.get("/api/geregistreerde/mail/" + geregistreerde.nummer)
            .then(res => {
                console.log("Mail then "  + res.data);
                toast.show({severity: 'success', summary: 'Info', detail: 'Mail gestuurd naar ' + geregistreerde.naam});
            })
    }
    const deleteGeregistreerde = (event, geregistreerde) => {
        event.stopPropagation();
        console.log("Delete: " + geregistreerde.naam);
        axios.delete(`/api/geregistreerde/delete/${geregistreerde.id}`)
          .then (() => {
            toast.current.show({severity: 'error', summary: 'Info', detail: geregistreerde.naam + ' verwijderd'});
            console.log("Geregistreerde verwijderd");
            const ger = geregistreerden.filter(g => g.id !== geregistreerde.id);
            setGeregistreerden(ger);
        });
    }
    
    const actiefTemplate = (rowData) => {
        return <input type="checkbox" defaultChecked={rowData.actief} onClick={(e) => toggleActief(e, rowData)}/>;
    }
    const afleveringActiefTemplate = (g, a) => {
        return <input type="checkbox" defaultChecked={a.actief} onClick={(e) => toggleAflevering(e, g, a)}/>;
    }
    const aktieTemplate = (rowData) => {
        let disabled = rowData.naam === "Kledingmaat" ? "disabled" : "";
        return <React.Fragment>
                   <Button className="p-button-rounded p-button-danger p-button-sm" disabled={disabled} icon="pi pi-times" onClick={(e) => deleteGeregistreerde(e, rowData)}/>
                   <Button className="p-button-rounded p-button-secundary p-button-sm" disabled={disabled} icon="pi pi-envelope" onClick={(e) => mail(e, rowData)}/>
               </React.Fragment>;
    }
    const vrijwilligersTemplate = (rowData) => {
        return <React.Fragment>
                   <input type="checkbox" defaultChecked={rowData.vrijwilligerVrijdag} onClick={(e) => toggleVrijwilligerVrijdag(e, rowData)}/>
                   <input type="checkbox" defaultChecked={rowData.vrijwilligerZaterdag} onClick={(e) => toggleVrijwilligerZaterdag(e, rowData)}/>
               </React.Fragment>;
    }
    
    const afleveringenTemplate = (data) => {
        console.log(data);
       return (
                <DataTable value={data.afleveringen}>
                    <Column field="volgnummer" header="Volgnummer" sortable></Column>
                    <Column field="datum" header="Datum" sortable></Column>
                    <Column field="actief" header="Aktief"  body={(rowdata) => afleveringActiefTemplate(data, rowdata)}></Column>
                </DataTable>
        );
    }
    
    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        if (newValue.trim().length > 0) {
            rowData[field] = newValue;
            save(rowData);
        } else {
            event.preventDefault();
        }
    }
    
    const cellEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    
    let telling = { aantal: geregistreerden.length,
                    actief: geregistreerden.filter(g => g.actief === true).length
                  };
        return (
<div className="row">

      <Toast ref={toast}/>
      <p>
      Aantal: {telling.aantal}<br/>
      Actief: {telling.actief}
      </p>

      <DataTable value={geregistreerden}
                 scrollHeight="200px"
                 className="geregistreerden p-datatable-gridlines p-datatable-striped p-datatable-sm"
                 expandedRows={afleveringen}
                 onRowToggle={(e) => setAfleveringen(e.data)}
                 rowExpansionTemplate={afleveringenTemplate} 
                 dataKey="nummer"
                 editMode="cell"
                 autoLayout={true}>
            <Column expander style={{ width: '1em' }} />
            <Column field="nummer" header="Nr" sortable className="p-text-right"/>
            <Column field="naam" header="Naam" sortable editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}>naam</Column>
            <Column field="telefoon" header="Telefoon">telefoon</Column>
            <Column field="email" header="Email" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}>email</Column>
            <Column header="Actief" body={actiefTemplate}></Column>
            <Column header="Vrijwilligers" body={vrijwilligersTemplate}/>
            <Column header="" body={aktieTemplate}/>
      </DataTable>
</div>
);
}

export default Geregistreerden;
