import React, { useState, useEffect, useRef } from 'react';
import { format, parse, addWeeks} from 'date-fns';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { addLocale, locale } from "primereact/api";

function Aflevering () {
    const [ afleveringen, setAfleveringen ] = useState([]);
    const [ huidige, setHuidige ] = useState({});
    const [ showDialog, setShowDialog ] = useState(false);
    const [ newDate, setNewDate ] = useState(new Date());
    const [ newVolgnummer, setNewVolgnummer ] = useState(0);
    const toast = useRef(null);

    useEffect(() => {
        axios.get("/api/aflevering/all")
            .then (res => {
                setAfleveringen(res.data);
            });
    }, []);
    
    useEffect(() => {
        axios.get("/api/aflevering/last")
            .then (res => {
                setHuidige(res.data);
                setNewVolgnummer(res.data.volgnummer + 1);
                const dtm1 = parse(res.data.datum, "yyyy-MM-dd", new Date());
                const dtm2 = addWeeks(dtm1, 26);
                setNewDate(dtm2);
            });
    }, [afleveringen]);

    addLocale('nl', {
        firstDayOfWeek: 1,
        dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        dayNamesShort: ['Zon', 'Maan', 'Dins', 'Woe', 'Don', 'Vrij', 'Zat'],
        dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        monthNames: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
        monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        today: 'Vandaag',
        clear: 'Wissen'
    });

    locale('nl'); 

    const addAflevering = () => {
        console.log("Add aflevering");
        setShowDialog(true);
    }

    const saveAflevering = (aflevering) => {
        console.log("Save aflevering");
        axios.post("/api/aflevering", aflevering)
            .then (res => {
                setAfleveringen(res.data);
            });
    }

    const saveNewAflevering = () => {
        console.log("Save new aflevering");
        axios.post("/api/aflevering", { volgnummer: newVolgnummer, datum: newDate})
            .then (res => {
                setAfleveringen(res.data);
            });
        setShowDialog(false);
    }

    const header = () => {
        return (
            <div>
                <Button icon="pi pi-plus" onClick={() => addAflevering()}/>
            </div>
        );
    }

    const deleteAflevering = (event, aflevering) => {
        event.stopPropagation();
        console.log("Delete: " + aflevering.volgnummer);
        axios.delete(`/api/aflevering/delete/${aflevering.id}`)
          .then (() => {
            toast.current.show({severity: 'error', summary: 'Info', detail: aflevering.volgnummer + ' verwijderd'});
            console.log("Aflevering verwijderd");
            const ger = afleveringen.filter(g => g.id !== aflevering.id);
            setAfleveringen(ger);
        });
    }
    
    const aktieTemplate = (rowData) => {
        let disabled = rowData.naam === "Kledingmaat" ? "disabled" : "";
        return <React.Fragment>
                   <Button className="p-button-rounded p-button-danger p-button-sm" disabled={disabled} icon="pi pi-times" onClick={(e) => deleteAflevering(e, rowData)}/>
               </React.Fragment>;
    }

    const calendarEditor = (options) => {
        const dtm1 = parse(options.value, "yyyy-MM-dd", new Date());
	    return <Calendar inline dateFormat="d MM yy" id="rdatum" value={dtm1} locale="nl"  onChange={(e) => options.editorCallback(format(e.target.value, "yyyy-MM-dd"))}/>
    }
    
    const onRowEditComplete1 = (e) => {
        let { newData } = e;
        saveAflevering(newData);
    }

        return <React.Fragment>
      <Toast ref={toast}/>
            { huidige &&
                <div>
                    {huidige.volgnummer} - {huidige.datum}
                </div>
            }
            <DataTable editMode="row"  onRowEditComplete={onRowEditComplete1} value={afleveringen} className="p-datatable-sm" header={header}>
                <Column header="Volgnummer" field="volgnummer"/>
                <Column editor={(options) => calendarEditor(options)} header="Datum" field="datum"/>
                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                <Column header="" body={aktieTemplate}/>
            </DataTable>
            <Dialog visible={showDialog} header="Aflevering toevoegen" onHide={() => setShowDialog(false)}>
                <div className="field grid">
                    <label for="volgnummer" className="col-fixed" style={{width: 150}} >Volgnummer</label>
                    <div className="col">
                        {newVolgnummer}
                    </div>
                </div>
                <div className="field grid">
                    <label for="datum" className="col-fixed" style={{width: 150}} >Datum</label>
                    <div className="col">
                        <Calendar inline dateFormat="d MM yy" id="datum" value={newDate} locale="nl" />
                    </div>
                </div>
                <div className="field grid">
                    <Button className="mr-auto" icon="pi pi-times" label="Annuleren" onClick={() => setShowDialog(false)} />
                    <Button className="p-button-danger" icon="pi pi-save" label="Opslaan" onClick={() => saveNewAflevering()} />
                </div>
            </Dialog>
        </React.Fragment>
    }

export default Aflevering;
