import React, { useState, useEffect } from 'react';
import Header from './components/header';
import Home from './components/home';
import Kopen from './components/kopen';
import Verkopen from './components/verkopen';
import Labelen from './components/labelen';
import Vrijwilligers from './components/vrijwilligers';
import Nieuws from './components/nieuws';
import NieuwsEdit from './components/nieuws-edit';
import Geregistreerden from './components/geregistreerden';
import Login from './components/login';
import Contact from './components/contact';
import Privacy from './components/privacy';
import Aflevering from './components/afleveringen';
import Instellingen from './components/instellingen';
import Registreren from './components/registreren';
import Helmet from 'react-helmet';
import axios from 'axios';
import 'primeflex/primeflex.css';
import { format, subDays} from 'date-fns';
import { nl } from 'date-fns/locale';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BrowserRouter as Router, NavLink, Routes, Route } from 'react-router-dom';
import Logout from './components/logout';

import './altenieuw.css';

function Altenieuw() {
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ datumZaterdag, setDatumZaterdag ] = useState("");
    const [ datumVrijdag, setDatumVrijdag ] = useState("");
    const [ beursOmschrijving, setBeursOmschrijving ] = useState("een herfst- en winterbeurs");
    const [ nieuws, setNieuws ] = useState("");
    const login = () => {
        console.log("Login");
        setLoggedIn(true);
    }
    const logout = () => {
        console.log("Logout");
        setLoggedIn(false);
    }
    useEffect(() => {
        axios.get("/api/altenieuw/nextdate").then(res => {
            const datum = new Date(res.data);
            const zaterdag = format(datum, "d MMMM y", { locale: nl});
            const vrijdag = format(subDays(datum, 1), "d MMMM y", {locale: nl });
            const maand = datum.getMonth();
            if (maand < 6) {
                setBeursOmschrijving("een lente- en zomerbeurs");
            } else {
                setBeursOmschrijving("een herfst- en winterbeurs");
            }
            setDatumZaterdag(zaterdag);
            setDatumVrijdag(vrijdag );
        });
        axios.get("/api/nieuws/all").then((res) => {
            setNieuws(res.data.tekst);
        });
    });
    
    const opslaan = (nieuws) => {
        console.log(nieuws);
        axios.post("/api/nieuws/save", nieuws, { headers: { 'Content-type': 'plain/text'}}).then((res) => {
            console.log(res.data);
        });
        setNieuws(nieuws);
    }

    return (
        <div className="grid">
            <Helmet>
                <title>Altenieuw - Kinderkledingbeurs in Alteveer/Craneveldt</title>
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
        <div className="col-12 alte-header">
            <Header />
        </div>
        <Router>
        <div className="col-12 navbar-custom">
            <div className="col-md-12" style={{ paddingLeft: 0, paddingRight: 0, height: 51 }}>
                <div className="p-tabmenu p-component">
                    <ul className="p-tabmenu-nav p-reset">
                        <li className="p-tabmenuitem">
                            <NavLink to="/home" className="p-menuitem-link" activeClassName="p-highlight">Home</NavLink>
                        </li>
                  <li className="p-tabmenuitem">
                    <NavLink to="/verkopen" className="p-menuitem-link" activeClassName="p-highlight">Verkopen</NavLink>
                  </li>
                  <li className="p-tabmenuitem">
                    <NavLink to="/kopen" className="p-menuitem-link" activeClassName="p-highlight">Kopen</NavLink>
                  </li>
                  <li className="p-tabmenuitem">
                    <NavLink to="/labelen" className="p-menuitem-link" activeClassName="p-highlight">Labelen</NavLink>
                  </li>
                  <li className="p-tabmenuitem">
                  <NavLink to="/vrijwilligers" className="p-menuitem-link" activeClassName="p-highlight">Vrijwilligers</NavLink>
                  </li>
                  <li className="p-tabmenuitem">
                    <NavLink to="/registreren" className="p-menuitem-link" activeClassName="p-highlight">Registreren</NavLink>
                  </li>
                  { loggedIn &&
                  <li className="p-tabmenuitem">
                    <NavLink to="/geregistreerden" className="p-menuitem-link" activeClassName="p-highlight">Geregistreerden</NavLink>
                  </li>
                  }
                  { loggedIn &&
                  <li className="p-tabmenuitem">
                    <NavLink to="/afleveringen" className="p-menuitem-link" activeClassName="p-highlight">Afleveringen</NavLink>
                  </li>
                  }
                  { loggedIn &&
                  <li className="p-tabmenuitem">
                    <NavLink to="/instellingen" className="p-menuitem-link" activeClassName="p-highlight">Instellingen</NavLink>
                  </li>
                  }
                  { loggedIn &&
                  <li className="p-tabmenuitem">
                    <NavLink to="/nieuws-edit" className="p-menuitem-link" activeClassName="p-highlight">Nieuws</NavLink>
                  </li>
                  }
                  <li className="p-tabmenuitem">
                    <NavLink to="/contact" className="p-menuitem-link" activeClassName="p-highlight">Contact</NavLink>
                  </li>
                  <li className="p-tabmenuitem">
                    <NavLink to="/privacy" className="p-menuitem-link" activeClassName="p-highlight">Privacy</NavLink>
                  </li>
                  { !loggedIn &&
                  <li className="p-tabmenuitem">
                    <NavLink to="/login" className="p-menuitem-link" activeClassName="p-highlight">Inloggen</NavLink>
              </li>
                  }
                  { loggedIn &&
                  <li className="p-tabmenuitem">
                    <Logout logout={logout} />
              </li>
                  }
            </ul>
            </div>
            </div>
        </div>
        <div className="col-1"/>
        <div className="col-9 altenieuw-content">
            <Routes>
                <Route path="/" exact element={
                    <Home datumZaterdag={datumZaterdag} beursOmschrijving={beursOmschrijving}/>
                  }>
                </Route>
                <Route path="/home" element={
                    <Home datumZaterdag={datumZaterdag}/>
                    }>
                </Route>
            <Route path="/kopen" element={
                <Kopen/>
                }>
                </Route>
            <Route path="/labelen" element={
                <Labelen/>
                }>
                </Route>
            <Route path="/verkopen" element={
                <Verkopen datumVrijdag={datumVrijdag}/>
                }>
                </Route>
            <Route path="/privacy" element={
                <Privacy />
                }>
            </Route>
            <Route path="/contact" element={
                <Contact/>
                }>
                </Route>
            <Route path="/geregistreerden" element=
                { loggedIn
                  ? <Geregistreerden/>
                  : <Home/>
                }>
            </Route>
            <Route path="/registreren" element={
                <Registreren loggedIn={loggedIn}/>
                }>
                </Route>
            <Route path="/afleveringen" element=
                { loggedIn
                  ? <Aflevering/>
                  : <Home/>
                }>
            </Route>
            <Route path="/instellingen" element=
                { loggedIn
                  ? <Instellingen/>
                  : <Home/>
                }>
            </Route>
            <Route path="/nieuws-edit" element=
                { loggedIn
                  ? <NieuwsEdit nieuws={nieuws} opslaan={opslaan}/>
                  : <Home/>
                }>
            </Route>
            <Route path="/vrijwilligers" element={
                <Vrijwilligers/>
                }>
                </Route>
            <Route path="/login" element={
                <Login login={() => login()}/>
                }>
                </Route>
            </Routes>
        </div>
        </Router>
        <div className="col-2 altenieuw-nieuws">
            <Nieuws nieuws={nieuws}/>
        </div>
    </div>
  )
}

export default Altenieuw;

