import React from 'react';


function Home(props) {
	return (
        <React.Fragment>
                <div className="font-bold pb-1 pt-2">ALTENIEUW</div>
                <div>
                     Op zaterdag {props.datumZaterdag} organiseren wij weer {props.beursOmschrijving}!
                </div>
                <div className="font-bold pb-1 pt-2">Kleding</div>
                <div>
                    Kinderkleding vanaf maat 74 tot en met 176.
                </div>
                <div className="font-bold pb-1 pt-2">Speelgoed</div>
                <div>
                   Kinderspeelgoed en kinderboeken.
                </div>
                <div className="font-bold pb-1 pt-2">Locatie</div>
                <div>
                    Je kunt ons vinden in de Viottastraat in Arnhem noord naast basisschool de Zyp.
                    Er is genoeg parkeergelegenheid.
                    Neem een stevige tas en contant geld (geen pin) mee, zodat je lekker kunt winkelen.
                </div>
                <img style={{float: 'right'}} alt="" src="./jongetjesacrylklein-modified-scale.png"/>
                <br/>
                <br/>
                <br/>
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fokkelien, Maartje en Sylvia
                <br/>
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.facebook.com/altenieuw" target="_fb">https://www.facebook.com/altenieuw</a>
        </React.Fragment>
    );
}

export default Home;
