import React, { useState } from 'react';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';

function NieuwsEdit(props) {
    
    const [ nieuws, setNieuws ] = useState(props.nieuws);
    
//    useEffect(() => {
//        axios.get("/api/nieuws/all").then((res) => {
//            console.log(res.data);
//            setNieuws(res.data.tekst);
//        })
//    });

    const opslaan = () => {
        console.log(nieuws);
        props.opslaan(nieuws);
    }

        return <React.Fragment>
                <Editor
                    value={nieuws}
                    label="Begin met typen ... "
                    onTextChange={(e) => setNieuws(e.htmlValue) }
                    >
                </Editor>
                <Button label="Opslaan" onClick={opslaan}/>
        </React.Fragment>
    
}

export default NieuwsEdit;
